var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0002862",
            tableId: "table",
            columns: _vm.grid.columns,
            gridHeight: "800px",
            data: _vm.grid.data,
            editable: false,
            filtering: false,
            checkClickFlag: false,
            merge: _vm.grid.merge,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "click"
                    ? [
                        _c(
                          "q-btn",
                          {
                            attrs: {
                              round: "",
                              unelevated: "",
                              size: "10px",
                              color: "amber",
                              icon: "search",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (() => {
                                  ;(_vm.rowIndex = props.rowIndex),
                                    (_vm.electronSignature =
                                      props.row.electronSignature)
                                }).apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "proxy" + props.rowIndex,
                                attrs: { breakpoint: 400 },
                              },
                              [
                                _c(col.component, {
                                  tag: "component",
                                  attrs: {
                                    popupParam: props.row,
                                    rowIndex: props.rowIndex,
                                  },
                                  on: { callback: _vm.callback },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.grid.data.length > 0 && _vm.editable,
                          expression: "grid.data.length>0 && editable",
                        },
                      ],
                      attrs: { label: "LBL0002863", icon: "print" },
                      on: { btnClicked: _vm.btnPrint },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }